import { IssuerPreferencesC as imported1_IssuerPreferencesC, IssuerPreferences as imported1_IssuerPreferences, issuerPreferencesC as imported1_issuerPreferencesC } from "./issuerPreferences";
import * as t from "io-ts";
import { headerPhotoC as imported8_headerPhotoC, headerPhotoC as imported9_headerPhotoC, HeaderPhoto as imported12_HeaderPhoto, HeaderPhoto as imported11_HeaderPhoto, HeaderPhotoC as imported11_HeaderPhotoC, HeaderPhotoC as imported13_HeaderPhotoC, HeaderPhotoC as imported12_HeaderPhotoC, headerPhotoC as imported10_headerPhotoC, HeaderPhoto as imported13_HeaderPhoto } from "./headerPhoto";
import { InvestorFocusCU as imported9_InvestorFocusCU, InvestorFocusU as imported4_InvestorFocusU, InvestorFocusCU as imported8_InvestorFocusCU, investorFocusOrd as imported3_investorFocusOrd } from "../domaintables/investorFocuses";
import { RelatedIssuerWithIssuerC as imported5_RelatedIssuerWithIssuerC, RelatedIssuerWithIssuer as imported5_RelatedIssuerWithIssuer, relatedIssuerWithIssuerC as imported5_relatedIssuerWithIssuerC } from "./relatedIssuer";
import { DisclaimerTypeCU as imported5_DisclaimerTypeCU, DisclaimerTypeU as imported2_DisclaimerTypeU, DisclaimerTypeCU as imported4_DisclaimerTypeCU } from "../domaintables/disclaimerTypes";
import { CustomPagesC as imported1_CustomPagesC, CustomPages as imported1_CustomPages, customPagesC as imported1_customPagesC } from "./customPages";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { AddressC as imported9_AddressC, Address as imported9_Address, addressC as imported7_addressC } from "./address";
import { HrefC as imported5_HrefC, Href as imported5_Href, hrefC as imported3_hrefC } from "./href";
import { WithStatusU as imported248_WithStatusU, withIdC as imported214_withIdC, WithStatusCU as imported493_WithStatusCU, WithStatusU as imported246_WithStatusU, WithStatusCU as imported495_WithStatusCU, WithStatusCU as imported504_WithStatusCU, WithStatusCU as imported499_WithStatusCU, WithStatusCU as imported506_WithStatusCU, WithStatusU as imported255_WithStatusU, WithStatusU as imported247_WithStatusU, WithStatusU as imported251_WithStatusU, WithStatusCU as imported509_WithStatusCU, WithId as imported214_WithId, WithStatusU as imported252_WithStatusU, WithStatusCU as imported501_WithStatusCU, WithStatusCU as imported505_WithStatusCU, WithStatusCU as imported512_WithStatusCU, WithStatusCU as imported492_WithStatusCU, WithId as imported213_WithId, WithStatusU as imported256_WithStatusU, WithStatusCU as imported502_WithStatusCU, WithStatusCU as imported498_WithStatusCU, WithStatusCU as imported513_WithStatusCU, WithStatusCU as imported500_WithStatusCU, WithIdC as imported213_WithIdC, WithStatusCU as imported508_WithStatusCU, WithStatusCU as imported497_WithStatusCU, withIdC as imported213_withIdC, WithStatusCU as imported503_WithStatusCU, WithStatusCU as imported494_WithStatusCU, WithIdC as imported214_WithIdC, WithStatusU as imported253_WithStatusU, WithStatusU as imported249_WithStatusU, WithStatusU as imported250_WithStatusU, WithStatusU as imported254_WithStatusU, WithStatusCU as imported511_WithStatusCU, WithStatusCU as imported510_WithStatusCU, WithStatusCU as imported507_WithStatusCU, WithStatusCU as imported496_WithStatusCU } from "./threadThrough";
import { IssuerC as imported50_IssuerC, issuerC as imported50_issuerC, Issuer as imported50_Issuer, IssuerC as imported52_IssuerC, Issuer as imported51_Issuer, issuerC as imported51_issuerC, IssuerC as imported51_IssuerC, Issuer as imported52_Issuer, issuerC as imported49_issuerC } from "./issuer";
import { SiteTemplateC as imported6_SiteTemplateC, SiteTemplate as imported7_SiteTemplate, SiteTemplateC as imported7_SiteTemplateC, siteTemplateC as imported5_siteTemplateC, SiteTemplate as imported6_SiteTemplate, siteTemplateC as imported4_siteTemplateC } from "./siteTemplate";
import { PageSortC as imported5_PageSortC, PageSort as imported5_PageSort, pageSortC as imported5_pageSortC } from "./pageSorts";
import { usersIssuerC as imported3_usersIssuerC, UsersIssuerC as imported2_UsersIssuerC, UsersIssuer as imported3_UsersIssuer, UsersIssuerC as imported3_UsersIssuerC, UsersIssuer as imported2_UsersIssuer, usersIssuerC as imported2_usersIssuerC } from "./usersIssuer";
import { DocumentTypeC as imported9_DocumentTypeC, DocumentType as imported9_DocumentType, documentTypeC as imported9_documentTypeC } from "./document";
import { Flash as imported11_Flash, Flash as imported10_Flash, flashC as imported9_flashC, flashC as imported10_flashC, Flash as imported7_Flash, FlashC as imported9_FlashC, FlashC as imported7_FlashC, Flash as imported8_Flash, flashC as imported11_flashC, FlashC as imported8_FlashC, flashC as imported7_flashC, FlashC as imported6_FlashC, flashC as imported8_flashC, FlashC as imported11_FlashC, flashC as imported6_flashC, FlashC as imported10_FlashC, Flash as imported6_Flash, Flash as imported9_Flash } from "./flash";
import { BLConfigC as imported2_BLConfigC, BLConfig as imported2_BLConfig, bLConfigC as imported2_bLConfigC } from "./blConfig";
import * as O from "fp-ts/lib/Option";
import { DisclaimerC as imported11_DisclaimerC, disclaimerC as imported7_disclaimerC, Disclaimer as imported9_Disclaimer, DisclaimerC as imported10_DisclaimerC, DisclaimerC as imported9_DisclaimerC, Disclaimer as imported11_Disclaimer, disclaimerC as imported6_disclaimerC, disclaimerC as imported5_disclaimerC, Disclaimer as imported10_Disclaimer } from "./disclaimer";
import { Bank as imported18_Bank, bankC as imported16_bankC, Bank as imported15_Bank, Bank as imported16_Bank, bankC as imported18_bankC, Bank as imported17_Bank, BankC as imported15_BankC, bankC as imported15_bankC, BankC as imported18_BankC, BankC as imported17_BankC, bankC as imported17_bankC, BankC as imported16_BankC } from "./bank";
import { ReadonlySetFromArrayC, readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { PageConfig as imported2_PageConfig, pageConfigC as imported2_pageConfigC, PageConfig as imported3_PageConfig, PageConfigC as imported2_PageConfigC, pageConfigC as imported3_pageConfigC, PageConfigC as imported3_PageConfigC } from "./pageConfig";
import { userWithRolesC as imported9_userWithRolesC, userWithRolesC as imported7_userWithRolesC, UserWithRoles as imported9_UserWithRoles, userWithRolesC as imported6_userWithRolesC, UserWithRoles as imported6_UserWithRoles, UserWithRoles as imported7_UserWithRoles, UserWithRoles as imported8_UserWithRoles, UserWithRolesC as imported9_UserWithRolesC, userWithRolesC as imported11_userWithRolesC, UserWithRolesC as imported8_UserWithRolesC, userWithRolesC as imported8_userWithRolesC, UserWithRolesC as imported7_UserWithRolesC, UserWithRolesC as imported11_UserWithRolesC, UserWithRoles as imported11_UserWithRoles, UserWithRolesC as imported10_UserWithRolesC, UserWithRoles as imported10_UserWithRoles, userWithRolesC as imported10_userWithRolesC, UserWithRolesC as imported6_UserWithRolesC } from "./user";
import { IntroSentenceEditableCU as imported7_IntroSentenceEditableCU, TitleEditableCU as imported6_TitleEditableCU, IntroSentenceEditableU as imported3_IntroSentenceEditableU, SortableU as imported3_SortableU, PageU as imported4_PageU, PhotoEditableCU as imported18_PhotoEditableCU, PhotoEditableCU as imported23_PhotoEditableCU, SortableCU as imported6_SortableCU, PhotoEditableCU as imported21_PhotoEditableCU, PhotoEditableU as imported11_PhotoEditableU, PhotoEditableCU as imported19_PhotoEditableCU, IntroSentenceEditableCU as imported6_IntroSentenceEditableCU, PageCU as imported8_PageCU, PageCU as imported9_PageCU, PhotoEditableCU as imported22_PhotoEditableCU, PageCU as imported10_PageCU, PageCU as imported11_PageCU, SortableCU as imported7_SortableCU, PageU as imported5_PageU, PhotoEditableU as imported9_PhotoEditableU, TitleEditableCU as imported7_TitleEditableCU, PhotoEditableU as imported10_PhotoEditableU, TitleEditableU as imported3_TitleEditableU, PhotoEditableCU as imported20_PhotoEditableCU } from "../domaintables/pages";
import { SectorCU as imported29_SectorCU, SectorU as imported14_SectorU, SectorCU as imported28_SectorCU, sectorOrd as imported3_sectorOrd } from "../domaintables/sectors";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";
import { OfficerC as imported7_OfficerC, Officer as imported7_Officer, officerC as imported7_officerC } from "./officer";
import { clientFeatureFlagsC as imported9_clientFeatureFlagsC, ClientFeatureFlagsC as imported10_ClientFeatureFlagsC, ClientFeatureFlags as imported10_ClientFeatureFlags, ClientFeatureFlagsC as imported13_ClientFeatureFlagsC, clientFeatureFlagsC as imported14_clientFeatureFlagsC, ClientFeatureFlags as imported13_ClientFeatureFlags, ClientFeatureFlags as imported8_ClientFeatureFlags, clientFeatureFlagsC as imported8_clientFeatureFlagsC, clientFeatureFlagsC as imported12_clientFeatureFlagsC, clientFeatureFlagsC as imported13_clientFeatureFlagsC, ClientFeatureFlagsC as imported9_ClientFeatureFlagsC, ClientFeatureFlagsC as imported8_ClientFeatureFlagsC, clientFeatureFlagsC as imported10_clientFeatureFlagsC, ClientFeatureFlagsC as imported14_ClientFeatureFlagsC, ClientFeatureFlagsC as imported11_ClientFeatureFlagsC, ClientFeatureFlags as imported11_ClientFeatureFlags, ClientFeatureFlags as imported12_ClientFeatureFlags, ClientFeatureFlags as imported14_ClientFeatureFlags, ClientFeatureFlagsC as imported12_ClientFeatureFlagsC, clientFeatureFlagsC as imported11_clientFeatureFlagsC, ClientFeatureFlags as imported9_ClientFeatureFlags } from "./clientFeatureFlags";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type BLWindowC<A1 extends t.Mixed> = t.TypeC<{
  BLSession: A1,
  BLConfig: imported2_BLConfigC
}>;
export type BLWindow<A1> = {
  BLSession: A1,
  BLConfig: imported2_BLConfig
};
export const bLWindowC = <A1 extends t.Mixed>(A1: A1): BLWindowC<A1> => t.type({
  BLSession: A1,
  BLConfig: imported2_bLConfigC
}) satisfies t.Type<BLWindow<t.TypeOf<A1>>, unknown>;


export type BLBaseSessionC = t.TypeC<{
  flash: t.ReadonlyArrayC<imported6_FlashC>,
  user: OptionFromNullableC<imported6_UserWithRolesC>
}>;
export type BLBaseSession = {
  flash: ReadonlyArray<imported6_Flash>,
  user: O.Option<imported6_UserWithRoles>
};
export const bLBaseSessionC: BLBaseSessionC = t.type({
  flash: t.readonlyArray(imported6_flashC),
  user: optionFromNullable(imported6_userWithRolesC)
}) satisfies t.Type<BLBaseSession, unknown>;


export type BLDealPortalSessionC = t.TypeC<{
  flash: t.ReadonlyArrayC<imported7_FlashC>,
  user: imported7_UserWithRolesC,
  bank: imported15_BankC,
  issuer: OptionFromNullableC<imported50_IssuerC>,
  usersIssuers: t.ReadonlyArrayC<imported213_WithIdC<imported2_UsersIssuerC>>,
  bankFeatureFlags: imported8_ClientFeatureFlagsC,
  issuerFeatureFlags: ReadonlyMapFromEntriesC<t.NumberC, imported9_ClientFeatureFlagsC>,
  issuerPages: ReadonlyMapFromEntriesC<t.NumberC, t.ReadonlyArrayC<imported2_PageConfigC<imported9_PageCU>>>
}>;
export type BLDealPortalSession = {
  flash: ReadonlyArray<imported7_Flash>,
  user: imported7_UserWithRoles,
  bank: imported15_Bank,
  issuer: O.Option<imported50_Issuer>,
  usersIssuers: ReadonlyArray<imported213_WithId<imported2_UsersIssuer>>,
  bankFeatureFlags: imported8_ClientFeatureFlags,
  issuerFeatureFlags: ReadonlyMap<number, imported9_ClientFeatureFlags>,
  issuerPages: ReadonlyMap<number, ReadonlyArray<imported2_PageConfig<imported4_PageU>>>
};
export const bLDealPortalSessionC: BLDealPortalSessionC = t.type({
  flash: t.readonlyArray(imported7_flashC),
  user: imported7_userWithRolesC,
  bank: imported15_bankC,
  issuer: optionFromNullable(imported49_issuerC),
  usersIssuers: t.readonlyArray(imported213_withIdC(imported2_usersIssuerC)),
  bankFeatureFlags: imported8_clientFeatureFlagsC,
  issuerFeatureFlags: readonlyMapFromEntries(t.number, numberOrd, imported9_clientFeatureFlagsC),
  issuerPages: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported2_pageConfigC(imported8_PageCU)))
}) satisfies t.Type<BLDealPortalSession, unknown>;


export type BLBankSitesSessionC = t.TypeC<{
  flash: t.ReadonlyArrayC<imported8_FlashC>,
  bank: imported16_BankC,
  user: OptionFromNullableC<imported8_UserWithRolesC>,
  siteTemplate: OptionFromNullableC<imported494_WithStatusCU<imported6_SiteTemplateC>>,
  headerPhotos: t.ReadonlyArrayC<t.TupleC<[imported19_PhotoEditableCU, imported495_WithStatusCU<imported11_HeaderPhotoC>]>>,
  featureFlags: imported10_ClientFeatureFlagsC
}>;
export type BLBankSitesSession = {
  flash: ReadonlyArray<imported8_Flash>,
  bank: imported16_Bank,
  user: O.Option<imported8_UserWithRoles>,
  siteTemplate: O.Option<imported246_WithStatusU<imported6_SiteTemplate>>,
  headerPhotos: ReadonlyArray<[imported9_PhotoEditableU, imported247_WithStatusU<imported11_HeaderPhoto>]>,
  featureFlags: imported10_ClientFeatureFlags
};
export const bLBankSitesSessionC: BLBankSitesSessionC = t.type({
  flash: t.readonlyArray(imported8_flashC),
  bank: imported16_bankC,
  user: optionFromNullable(imported8_userWithRolesC),
  siteTemplate: optionFromNullable(imported492_WithStatusCU(imported4_siteTemplateC)),
  headerPhotos: t.readonlyArray(t.tuple([imported18_PhotoEditableCU, imported493_WithStatusCU(imported8_headerPhotoC)])),
  featureFlags: imported10_clientFeatureFlagsC
}) satisfies t.Type<BLBankSitesSession, unknown>;


export type BLIssuerSitesSessionC = t.TypeC<{
  flash: t.ReadonlyArrayC<imported9_FlashC>,
  issuer: imported51_IssuerC,
  iffs: imported11_ClientFeatureFlagsC,
  bank: OptionFromNullableC<imported17_BankC>,
  bankFeatureFlags: imported12_ClientFeatureFlagsC,
  primaryOfficer: OptionFromNullableC<imported504_WithStatusCU<imported7_OfficerC>>,
  docTypes: t.ReadonlyArrayC<imported9_DocumentTypeC>,
  user: OptionFromNullableC<imported9_UserWithRolesC>,
  userFocuses: ReadonlySetFromArrayC<imported9_InvestorFocusCU>,
  userSectors: ReadonlySetFromArrayC<imported29_SectorCU>,
  userSubscribed: t.BooleanC,
  headerPhotos: t.ReadonlyArrayC<t.TupleC<[imported21_PhotoEditableCU, imported505_WithStatusCU<imported12_HeaderPhotoC>]>>,
  disclaimers: t.ReadonlyArrayC<imported506_WithStatusCU<imported9_DisclaimerC>>,
  footerDisclaimerTypes: t.ReadonlyArrayC<imported5_DisclaimerTypeCU>,
  exitDisclaimer: OptionFromNullableC<imported507_WithStatusCU<imported10_DisclaimerC>>,
  dueDisclaimer: t.ReadonlyArrayC<t.TupleC<[t.StringC, imported508_WithStatusCU<imported11_DisclaimerC>]>>,
  hrefs: t.ReadonlyArrayC<imported509_WithStatusCU<imported5_HrefC>>,
  siteTemplate: OptionFromNullableC<imported510_WithStatusCU<imported7_SiteTemplateC>>,
  relatedIssuers: t.ReadonlyArrayC<imported511_WithStatusCU<imported5_RelatedIssuerWithIssuerC>>,
  prefs: OptionFromNullableC<imported1_IssuerPreferencesC>,
  pages: t.ReadonlyArrayC<imported3_PageConfigC<imported11_PageCU>>,
  hasIrmaLetter: t.BooleanC
}>;
export type BLIssuerSitesSession = {
  flash: ReadonlyArray<imported9_Flash>,
  issuer: imported51_Issuer,
  iffs: imported11_ClientFeatureFlags,
  bank: O.Option<imported17_Bank>,
  bankFeatureFlags: imported12_ClientFeatureFlags,
  primaryOfficer: O.Option<imported248_WithStatusU<imported7_Officer>>,
  docTypes: ReadonlyArray<imported9_DocumentType>,
  user: O.Option<imported9_UserWithRoles>,
  userFocuses: ReadonlySet<imported4_InvestorFocusU>,
  userSectors: ReadonlySet<imported14_SectorU>,
  userSubscribed: boolean,
  headerPhotos: ReadonlyArray<[imported10_PhotoEditableU, imported249_WithStatusU<imported12_HeaderPhoto>]>,
  disclaimers: ReadonlyArray<imported250_WithStatusU<imported9_Disclaimer>>,
  footerDisclaimerTypes: ReadonlyArray<imported2_DisclaimerTypeU>,
  exitDisclaimer: O.Option<imported251_WithStatusU<imported10_Disclaimer>>,
  dueDisclaimer: ReadonlyArray<[string, imported252_WithStatusU<imported11_Disclaimer>]>,
  hrefs: ReadonlyArray<imported253_WithStatusU<imported5_Href>>,
  siteTemplate: O.Option<imported254_WithStatusU<imported7_SiteTemplate>>,
  relatedIssuers: ReadonlyArray<imported255_WithStatusU<imported5_RelatedIssuerWithIssuer>>,
  prefs: O.Option<imported1_IssuerPreferences>,
  pages: ReadonlyArray<imported3_PageConfig<imported5_PageU>>,
  hasIrmaLetter: boolean
};
export const bLIssuerSitesSessionC: BLIssuerSitesSessionC = t.type({
  flash: t.readonlyArray(imported9_flashC),
  issuer: imported50_issuerC,
  iffs: imported11_clientFeatureFlagsC,
  bank: optionFromNullable(imported17_bankC),
  bankFeatureFlags: imported12_clientFeatureFlagsC,
  primaryOfficer: optionFromNullable(imported496_WithStatusCU(imported7_officerC)),
  docTypes: t.readonlyArray(imported9_documentTypeC),
  user: optionFromNullable(imported9_userWithRolesC),
  userFocuses: readonlySetFromArray(imported8_InvestorFocusCU, imported3_investorFocusOrd),
  userSectors: readonlySetFromArray(imported28_SectorCU, imported3_sectorOrd),
  userSubscribed: t.boolean,
  headerPhotos: t.readonlyArray(t.tuple([imported20_PhotoEditableCU, imported497_WithStatusCU(imported9_headerPhotoC)])),
  disclaimers: t.readonlyArray(imported498_WithStatusCU(imported5_disclaimerC)),
  footerDisclaimerTypes: t.readonlyArray(imported4_DisclaimerTypeCU),
  exitDisclaimer: optionFromNullable(imported499_WithStatusCU(imported6_disclaimerC)),
  dueDisclaimer: t.readonlyArray(t.tuple([t.string, imported500_WithStatusCU(imported7_disclaimerC)])),
  hrefs: t.readonlyArray(imported501_WithStatusCU(imported3_hrefC)),
  siteTemplate: optionFromNullable(imported502_WithStatusCU(imported5_siteTemplateC)),
  relatedIssuers: t.readonlyArray(imported503_WithStatusCU(imported5_relatedIssuerWithIssuerC)),
  prefs: optionFromNullable(imported1_issuerPreferencesC),
  pages: t.readonlyArray(imported3_pageConfigC(imported10_PageCU)),
  hasIrmaLetter: t.boolean
}) satisfies t.Type<BLIssuerSitesSession, unknown>;


export type BLIssuerPortalSessionC = t.TypeC<{
  flash: t.ReadonlyArrayC<imported10_FlashC>,
  customPages: imported1_CustomPagesC,
  issuer: imported52_IssuerC,
  bank: OptionFromNullableC<imported18_BankC>,
  address: imported9_AddressC,
  user: imported10_UserWithRolesC,
  usersIssuers: t.ReadonlyArrayC<imported214_WithIdC<imported3_UsersIssuerC>>,
  iffs: imported13_ClientFeatureFlagsC,
  bankFeatureFlags: OptionFromNullableC<imported14_ClientFeatureFlagsC>,
  openDraftCount: t.NumberC,
  headerPhotos: t.ReadonlyArrayC<t.TupleC<[imported23_PhotoEditableCU, imported513_WithStatusCU<imported13_HeaderPhotoC>]>>,
  introSentences: t.ReadonlyArrayC<t.TupleC<[imported7_IntroSentenceEditableCU, MarkdownC]>>,
  pageTitles: t.ReadonlyArrayC<t.TupleC<[imported7_TitleEditableCU, t.StringC]>>,
  pageSorts: t.ReadonlyArrayC<t.TupleC<[imported7_SortableCU, imported5_PageSortC]>>
}>;
export type BLIssuerPortalSession = {
  flash: ReadonlyArray<imported10_Flash>,
  customPages: imported1_CustomPages,
  issuer: imported52_Issuer,
  bank: O.Option<imported18_Bank>,
  address: imported9_Address,
  user: imported10_UserWithRoles,
  usersIssuers: ReadonlyArray<imported214_WithId<imported3_UsersIssuer>>,
  iffs: imported13_ClientFeatureFlags,
  bankFeatureFlags: O.Option<imported14_ClientFeatureFlags>,
  openDraftCount: number,
  headerPhotos: ReadonlyArray<[imported11_PhotoEditableU, imported256_WithStatusU<imported13_HeaderPhoto>]>,
  introSentences: ReadonlyArray<[imported3_IntroSentenceEditableU, Markdown]>,
  pageTitles: ReadonlyArray<[imported3_TitleEditableU, string]>,
  pageSorts: ReadonlyArray<[imported3_SortableU, imported5_PageSort]>
};
export const bLIssuerPortalSessionC: BLIssuerPortalSessionC = t.type({
  flash: t.readonlyArray(imported10_flashC),
  customPages: imported1_customPagesC,
  issuer: imported51_issuerC,
  bank: optionFromNullable(imported18_bankC),
  address: imported7_addressC,
  user: imported10_userWithRolesC,
  usersIssuers: t.readonlyArray(imported214_withIdC(imported3_usersIssuerC)),
  iffs: imported13_clientFeatureFlagsC,
  bankFeatureFlags: optionFromNullable(imported14_clientFeatureFlagsC),
  openDraftCount: t.number,
  headerPhotos: t.readonlyArray(t.tuple([imported22_PhotoEditableCU, imported512_WithStatusCU(imported10_headerPhotoC)])),
  introSentences: t.readonlyArray(t.tuple([imported6_IntroSentenceEditableCU, markdownC])),
  pageTitles: t.readonlyArray(t.tuple([imported6_TitleEditableCU, t.string])),
  pageSorts: t.readonlyArray(t.tuple([imported6_SortableCU, imported5_pageSortC]))
}) satisfies t.Type<BLIssuerPortalSession, unknown>;


export type BLInvestorPortalSessionC = t.TypeC<{
  flash: t.ReadonlyArrayC<imported11_FlashC>,
  user: imported11_UserWithRolesC
}>;
export type BLInvestorPortalSession = {
  flash: ReadonlyArray<imported11_Flash>,
  user: imported11_UserWithRoles
};
export const bLInvestorPortalSessionC: BLInvestorPortalSessionC = t.type({
  flash: t.readonlyArray(imported11_flashC),
  user: imported11_userWithRolesC
}) satisfies t.Type<BLInvestorPortalSession, unknown>;


