import type { ChangeEvent, InputHTMLAttributes, ReactElement } from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constFalse, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import type { ReadonlyNonEmptyArray } from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as s from "fp-ts/lib/string";

import { gtagClickEvent } from "@scripts/analytics";
import type { BLConfigWithLog } from "@scripts/bondlink";
import type { Primitive } from "@scripts/fp-ts/lib/types/matchers";
import type { BaseInputFieldProps, BaseInputProps } from "@scripts/react/components/form/Form";
import { useConfig } from "@scripts/react/context/Config";
import type { DataCodec, StateProps, UnsafeFormProp, ValErrMsgAR } from "@scripts/react/form/form";
import { clearAndSetState, getValErr, updateAndSetState, useUpdateAndSetStateViaCodecTransition } from "@scripts/react/form/form";
import { reactChildToStringStripTagsE } from "@scripts/react/syntax/react";
import type { Klass, KlassProp } from "@scripts/react/util/classnames";
import { klass, klassConditionalArr, klassPropO } from "@scripts/react/util/classnames";
import { eq } from "@scripts/util/eq";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";
import { getLabel, type LabelOrAriaLabel, uniqueAriaId } from "@scripts/util/labelOrAriaLabel";
import type { NonArray } from "@scripts/util/nonArray";

import { ButtonLink } from "../Button";
import { constEmpty, mapOrEmpty, mapWithKeyOrEmpty, toEmpty } from "../Empty";
import { disabledClass, errorClass, LabelElBase, valErrMsgEls } from "./Labels";

export type Label = O.Option<string | ReactElement>;

export type IndicatorOption<KV, L extends Label = Label> = {
  label: L;
  value: KV;
  description?: string;
  disabled?: boolean;
};

type CustomIndicatorTypePropsBase<KV> = BaseInputFieldProps<KV> & CustomIndicatorPropsBase<KV>;
type CustomIndicatorArrayProps<PC extends DataCodec, KV extends Primitive> = CustomIndicatorTypePropsBase<KV> & StateProps<PC, ReadonlyArray<KV>> & { groupLabel: string };

const generateOptionLabel = (label: Label, key: O.Option<string>, tooltip?: ReactElement) => pipe(
  key,
  O.fold(
    () => pipe(label, mapOrEmpty(l => <span {...klass("option-label")}>{l}{tooltip}</span>)),
    _ => pipe(label, mapWithKeyOrEmpty((l, k) => <span id={k} {...klass("option-label")}>{l}{tooltip}</span>, _))
  )
);

const mkAriaLabel = (
  o: Pick<IndicatorOption<unknown>, "description" | "label">,
  defaultLabel: string,
) => pipe(
  o.description,
  O.fromNullable,
  O.getOrElse(() => defaultLabel),
  notLabel => pipe(
    o.label,
    O.fold(
      () => notLabel,
      _ => s.isString(_) ? _ : notLabel
    ),
  )
);

const checkboxCompare = <KV extends string | number>(optionValue: KV) =>
  O.fold(
    () => false,
    RA.exists(eq(optionValue))
  );

export type CustomInputProps =
  Pick<InputHTMLAttributes<HTMLInputElement>, "disabled" | "onChange" | "tabIndex" | "value">
  & Pick<CustomIndicatorPropsBase<unknown>, "ariaUId" | "name" | "tooltip" | "type">
  & Pick<IndicatorOption<unknown>, "description" | "label"> & {
    checked: boolean;
    labelKlasses?: KlassProp;
  };

export const CustomInput = (props: CustomInputProps) => {
  const ariaUId = pipe(
    props.ariaUId,
    O.getOrElse(() => pipe(
      mkAriaLabel({ description: props.description, label: props.label }, props.name),
      uniqueAriaId
    ))
  );

  return (
    <label {...klassPropO(["custom-indicator", disabledClass(props.disabled)])(props.labelKlasses)}>
      <input
        aria-labelledby={ariaUId}
        checked={props.checked}
        disabled={props.disabled}
        name={props.name}
        onChange={props.onChange}
        tabIndex={props.tabIndex}
        type={props.type}
        value={props.value}
      />
      {generateOptionLabel(props.label, O.some(ariaUId), props.tooltip)}
      <div className="indicator" />
    </label>
  );
};

export const CustomIndicatorDescription = (props: { description: string }) =>
  <span className={"custom-indicator-description"}>{props.description}</span>;

export const CustomIndicatorInput = <KV extends string | number>(props: CustomIndicatorRawBaseProps<KV> & { option: IndicatorOption<KV> }) => {
  const checked = props.type === "checkbox" ? checkboxCompare(props.option.value)(props.value) : O.exists(eq(props.option.value))(props.value);

  const onChange = useCallback(() => {
    if (!checked) {
      props.type === "checkbox"
        ? props.onChange(Array.from(new Set(O.getOrElse(RA.zero<KV>)(props.value)).add(props.option.value)))
        : props.onChange(props.option.value);
    } else {
      props.type === "checkbox" && props.onChange(O.getOrElse(RA.zero<KV>)(props.value).filter(_ => _ !== props.option.value));
    }
  }, [checked, props]);

  const isCustomInputDisabled = (props.option.disabled ?? props.disabled ?? false);

  return <>
    <CustomInput
      ariaUId={O.none}
      disabled={isCustomInputDisabled}
      label={props.option.label}
      labelKlasses={(klassConditionalArr(["checked"], [])([checked])).className}
      name={props.name}
      type={props.type}
      value={props.option.value}
      checked={checked}
      onChange={onChange}
      tabIndex={props.tabIndex}
    />
    {props.option.description && <CustomIndicatorDescription description={props.option.description} />}
  </>;
};

export type CustomIndicatorRawBaseProps<KV extends string | number> = {
  name: string;
  unselectedValue: O.Option<KV>;
  tabIndex?: number;
  disabled?: boolean;
  klasses?: Klass;
  hideErrorMessage?: true;
} & ({
  type: "radio";
  onChange: (e: KV) => void;
  value: O.Option<KV>;
} | {
  type: "checkbox";
  onChange: (e: ReadonlyArray<KV>) => void;
  value: O.Option<ReadonlyArray<KV>>;
});

export type CustomIndicatorRawProps<KV extends string | number> = CustomIndicatorRawBaseProps<KV> & {
  options: ReadonlyNonEmptyArray<IndicatorOption<KV>>;
  errors: ValErrMsgAR<UnsafeFormProp<KV> | UnsafeFormProp<KV[]>>;
  disabled?: boolean;
};

export function CustomIndicatorRaw<KV extends string | number>(props: CustomIndicatorRawProps<KV>): ReactElement {
  return (
    <div {...klass("form-input", errorClass(props.errors), disabledClass(props.disabled), fromNullableOrOption(props.klasses))}>
      {props.options.map((o) => <CustomIndicatorInput key={`${o.value}`} {...props} option={o} />)}
      {!props.hideErrorMessage && valErrMsgEls(O.some(props.name), O.none)(props.errors)}
    </div>
  );
}

type CustomIndicatorPropsBase<KV, InnerKV = KV> = {
  name: string;
  type: "checkbox" | "radio";
  options: ReadonlyArray<IndicatorOption<InnerKV>>;
  unselectedValue: O.Option<KV>;
  // Useful for tracking click events in GA for things like filters where there would be no form submission but we
  // want to track the usage. -JDL
  analyticsScope: O.Option<string>;
  ariaUId: O.Option<string>;
  tooltip?: ReactElement;
};

function CustomIndicatorBase<PC extends DataCodec, KV>(props: Omit<CustomIndicatorProps<PC, KV>, "lens"> & {
  checked: (o: IndicatorOption<KV>) => boolean;
  onChange: (o: IndicatorOption<KV>, e: ChangeEvent<HTMLInputElement>) => void;
  valErrNode: () => ReactElement;
  veErr: ValErrMsgAR<UnsafeFormProp<KV> | UnsafeFormProp<KV[]>>;
  onClear?: () => void;
}): ReactElement {
  const optionsRNEA = RNEA.fromReadonlyArray(props.options);
  const isAnyOptionSelected = pipe(optionsRNEA, O.fold(
    constFalse,
    RA.exists(_ => props.checked(_)))
  );
  return <>{pipe(
    optionsRNEA,
    mapOrEmpty(options =>
      <div
        role="group"
        {...pipe(props.ariaUId, O.fold(() => ({}), _ => ({ "aria-labelledby": _ })))}
        {...klass("form-input", errorClass(props.veErr), disabledClass(props.disabled))}
      >
        {options.map((o, idx) => {
          // eslint-disable-next-line react/no-array-index-key
          return <Fragment key={idx.toString()}>
            <div className="d-flex">
              <CustomInput
                ariaUId={props.ariaUId}
                label={o.label}
                name={props.name}
                type={props.type}
                value={O.getOrElse(() => "")(props.codec.encode(o.value))}
                checked={props.checked(o)}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={e => props.onChange(o, e)}
                tabIndex={props.tabIndex}
                disabled={props.disabled || o.disabled}
              />
              {toEmpty(O.fromNullable(props.tooltip))}
            </div>
            {o.description && <span className={"custom-indicator-description"}>{o.description}</span>}
          </Fragment>;
        })}
        {pipe(props.onClear, O.fromNullable, mapOrEmpty((c) => <ButtonLink className={isAnyOptionSelected ? "" : "v-hidden"} onClick={c}>Clear</ButtonLink>))}
        {props.valErrNode()}
      </div>
    )
  )}</>;
}

const handleAnalytics = (config: BLConfigWithLog, analyticsScope: O.Option<string>, label: Label) => (checked: boolean) =>
  pipe(analyticsScope,
    O.map(scope =>
      gtagClickEvent("checkbox")(
        `${scope} ${pipe(label, O.chain(_ => O.fromEither(reactChildToStringStripTagsE(config, _))), O.getOrElse(() => "no label"))} ${checked.toString()}`
      )
    )
  );

export type CustomIndicatorProps<PC extends DataCodec, KV, InnerKV = KV> =
  BaseInputProps<PC, KV> & CustomIndicatorPropsBase<KV, InnerKV> & {
    labelOrAriaLabel?: LabelOrAriaLabel;
    required?: boolean;
    isClearable?: boolean;
  };

export function CustomIndicator<PC extends DataCodec, InnerKV>(
  props: CustomIndicatorProps<PC, O.Option<NonArray<InnerKV>>, NonArray<InnerKV>>
): JSX.Element;
export function CustomIndicator<PC extends DataCodec, KV>(props: CustomIndicatorProps<PC, NonArray<KV>>): JSX.Element;
export function CustomIndicator<PC extends DataCodec, KV>(props: CustomIndicatorProps<PC, NonArray<KV>>): ReactElement {
  const config = useConfig();
  const [ve, dispatchVe] = useState(getValErr(props.state, props.lens)(config));
  useEffect(() => dispatchVe(getValErr(props.state, props.lens)(config)), [config, props.state, props.lens]);

  const label = pipe(props.labelOrAriaLabel, O.fromNullable, O.chain(getLabel));
  const hasLabel = O.isSome(label);

  const [localValue, updateStateTransition] = useUpdateAndSetStateViaCodecTransition<NonArray<KV>>(ve.val, props.codec.eq);
  const onChange = useCallback((o: IndicatorOption<NonArray<KV>>, e: ChangeEvent<HTMLInputElement>) => {
    handleAnalytics(config, props.analyticsScope, o.label)(e.target.checked);
    return e.target.checked
      ? updateStateTransition(props.setState, props.lens, props.codec)(o.value)
      : pipe(
        props.unselectedValue,
        O.fold(
          () => clearAndSetState(props.setState, props.lens),
          updateStateTransition(props.setState, props.lens, props.codec)
        )
      );
  }, [config, updateStateTransition, props.analyticsScope, props.codec, props.lens, props.setState, props.unselectedValue]);

  const isChecked = useCallback((o: IndicatorOption<NonArray<KV>>) => {
    return O.exists((lv: NonArray<KV>) => props.codec.eq.equals(o.value, lv))(localValue);
  }, [localValue, props.codec.eq]);

  const valErrNode = useCallback(() => valErrMsgEls(label, pipe(ve.val, O.chain(props.codec.encode)))(ve.err), [ve, props.codec.encode, label]);

  const onClear = useCallback(
    () => clearAndSetState(props.setState, props.lens),
    [props.setState, props.lens]
  );

  return <div {...klassPropO(["custom-indicator-wrapper", disabledClass(props.disabled), errorClass(ve.err)])(O.none)}>
    {mapOrEmpty((l: string) =>
      <div className="custom-indicator-label">
        <LabelElBase
          label={l}
          id={l}
          required={props.required ?? false}
          klass={klassPropO(["font-size-default"])(O.none).className}
        />
        {valErrNode()}
      </div>
    )(label)}
    <CustomIndicatorBase
      name={props.name}
      type={props.type}
      options={props.options}
      unselectedValue={props.unselectedValue}
      analyticsScope={props.analyticsScope}
      codec={props.codec}
      state={props.state}
      setState={props.setState}
      checked={isChecked}
      onChange={onChange}
      valErrNode={hasLabel ? constEmpty : valErrNode}
      veErr={hasLabel ? E.right([]) : ve.err}
      ariaUId={props.ariaUId}
      tabIndex={props.tabIndex}
      tooltip={props.tooltip}
      disabled={props.disabled}
      onClear={pipe(onClear, O.fromPredicate(() => props.isClearable ?? false), O.toUndefined)}
    />
  </div>;
}

export function CustomIndicatorArray<PC extends DataCodec, KV extends Primitive>(props: CustomIndicatorArrayProps<PC, KV>): ReactElement {
  const config = useConfig();
  const [ve, dispatchVe] = useState(getValErr(props.state, props.lens)(config));
  useEffect(() => dispatchVe(getValErr(props.state, props.lens)(config)), [config, props.state, props.lens]);

  const isChecked = useCallback((o: IndicatorOption<KV>) => pipe(ve.val, O.map(RA.exists(eq(o.value))), O.getOrElse(() => false)), [ve]);
  const onChange = useCallback((o: IndicatorOption<KV>, e: ChangeEvent<HTMLInputElement>) => {
    handleAnalytics(config, props.analyticsScope, o.label)(e.target.checked);
    pipe(
      ve.val,
      O.fold(
        () => e.target.checked ? O.some([o.value]) : O.none,
        v => O.some(e.target.checked ? RA.append(o.value)(v) : RA.filter(i => i !== o.value)(v))
      ),
      O.filter(RA.isNonEmpty),
      O.map(RA.uniq<KV>(Eq.eqStrict)),
      O.fold(
        () => updateAndSetState(props.setState, props.lens)([]),
        updateAndSetState(props.setState, props.lens)
      )
    );
  }, [config, props.analyticsScope, props.lens, props.setState, ve]);

  const valErrNode = useCallback(() =>
    valErrMsgEls(
      O.some(props.groupLabel),
      pipe(
        ve.val,
        O.filter(RA.isNonEmpty),
        O.chain(RA.traverse(O.Applicative)(props.codec.encode)),
        O.map(v => v.join(", "))
      )
    )(ve.err),
    [ve, props.codec.encode, props.groupLabel]
  );

  return <CustomIndicatorBase
    name={props.name}
    type={props.type}
    options={props.options}
    unselectedValue={props.unselectedValue}
    analyticsScope={props.analyticsScope}
    codec={props.codec}
    state={props.state}
    setState={props.setState}
    checked={isChecked}
    onChange={onChange}
    valErrNode={valErrNode}
    veErr={ve.err}
    ariaUId={props.ariaUId}
    tooltip={props.tooltip}
    disabled={props.disabled}
  />;
}
