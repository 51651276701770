import { lookup } from "fp-ts/lib/Array";
import * as Eq from "fp-ts/lib/Eq";
import { constVoid, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { fromNullable, getOrElse, map } from "fp-ts/lib/Option";
import * as s from "fp-ts/lib/string";
import * as t from "io-ts";

import { type RelativeUrl, unsafeFromRelativeUrlString } from "@scripts/codecs/relativeUrl";
import { tap } from "@scripts/util/tap";

import { eq } from "../util/eq";
import { replaceState } from "./router";

export const methodC = t.union([t.literal("GET"), t.literal("POST"), t.literal("OPTIONS"), t.literal("DELETE")]);
export type MethodC = typeof methodC;
export type Method = t.TypeOf<MethodC>;

export type UrlInterfaceC<M extends Method> = t.TypeC<{ method: t.LiteralC<M>, url: t.StringC }>;
export type UrlInterface<M extends Method> = { method: M, url: string };
export const urlInterfaceC = <M extends Method>(M: M): UrlInterfaceC<M> => t.type({
  method: t.literal(M),
  url: t.string,
});

export const urlInterface = <M extends Method>(method: M, url: string): UrlInterface<M> =>
  ({ method, url });

export interface UrlIO<I, O> {
  readonly input: I;
  readonly output: O;
}

export interface UrlInterfaceIO<M extends Method, I, O> extends UrlInterface<M>, UrlIO<I, O> { }

export type RelativeUrlInterface<M extends Method> = Omit<UrlInterface<M>, "url"> & { url: RelativeUrl };

export const relativeUrlInterface = <M extends Method>(method: M, url: RelativeUrl): RelativeUrlInterface<M> =>
  ({ method, url });

export type RelativeUrlInterfaceIO<M extends Method, I, O> = Omit<UrlInterfaceIO<M, I, O>, "url"> & { url: RelativeUrl };

export const relativeUrlInterfaceIO = <M extends Method, I, O>(method: M, url: RelativeUrl, io: UrlIO<I, O>): RelativeUrlInterfaceIO<M, I, O> =>
  ({ method, url, input: io.input, output: io.output });

export const urlEq = Eq.struct<UrlInterface<Method>>({ url: s.Eq, method: Eq.fromEquals(eq) });
export const urlWoQs = <M extends Method>(u: UrlInterface<M>): UrlInterface<M> => ({ ...u, url: getOrElse(() => u.url)(lookup(0, u.url.split("?"))) });

export const prefixUrl = <M extends Method>(url: UrlInterface<M>, prefix: string): UrlInterface<M> =>
  Object.assign({}, url, { url: prefix + url.url });

export const urlWithQuery = (url: string, query: URLSearchParams): string => {
  const queryStr = query.toString();
  return `${url}${queryStr === "" ? "" : `?${queryStr}`}`;
};

// The types say location isn't nullable but it returns null server side
const getLocation = (): O.Option<Location> => O.fromNullable(globalThis.location);

export const modifyAbsoluteUrl = (
  modOrigin: (origin: string) => string,
  modPath: (path: string) => string,
  modQuery: (query: URLSearchParams) => URLSearchParams,
): O.Option<string> => pipe(
  getLocation(),
  O.map(l => urlWithQuery(
    `${modOrigin(l.origin)}${modPath(l.pathname)}`,
    modQuery(new URLSearchParams(l.search))
  )),
);

export const getAbsoluteUrl = (): O.Option<string> => modifyAbsoluteUrl(identity, identity, identity);

const getRelative = (f: (l: Location) => string) => {
  function go(): O.Option<RelativeUrl>;
  function go(l: Location): RelativeUrl;
  function go(l?: Location): O.Option<RelativeUrl> | RelativeUrl {
    return l ? unsafeFromRelativeUrlString(f(l)) : pipe(getLocation(), O.map(_ => unsafeFromRelativeUrlString(f(_))));
  }
  return go;
};

export const getRelativePath = getRelative(_ => _.pathname);
export const getRelativeUrl = getRelative(_ => urlWithQuery(_.pathname, new URLSearchParams(_.search)));

export const replaceUrlState = (
  modOrigin: (origin: string) => string,
  modPath: (path: string) => string,
  modQuery: (query: URLSearchParams) => URLSearchParams,
): void => pipe(modifyAbsoluteUrl(modOrigin, modPath, modQuery), O.fold(constVoid, replaceState));

export const removeUrlParam = (param: string): O.Option<void> => pipe(
  fromNullable(new URLSearchParams(window.location.search).get(param)),
  map(() => replaceUrlState(identity, identity, tap(q => q.delete(param)))),
);
