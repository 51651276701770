/* eslint-disable @typescript-eslint/no-restricted-imports */
/* eslint-disable curly-quotes/no-straight-quotes */
import * as J from "@js-joda/core";
import * as JE from "@js-joda/extra";
import { YearQuarter } from "@js-joda/extra";
import { Locale } from "@js-joda/locale_en-us";
import type { Eq } from "fp-ts/lib/Eq";
import type { Lazy } from "fp-ts/lib/function";
import { constant, flow, pipe } from "fp-ts/lib/function";
import type * as Rdr from "fp-ts/lib/Reader";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { ET, LocalDateC, LocalDateTimeFromIsoStringC } from "@scripts/codecs/localDate";
import { E, O } from "@scripts/fp-ts";
import type { SafeCallError } from "@scripts/util/dangerousSafeCall";
import { dangerousSafeCall } from "@scripts/util/dangerousSafeCall";
import { tap } from "@scripts/util/tap";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Joda {
  export type LocalDate = J.LocalDate;
  export type LocalDateTime = J.LocalDateTime;

  // duration
  export type Duration = J.Duration;
  export const Duration = J.Duration;

  // range
  export type LocalDateRange = JE.LocalDateRange;
  export const LocalDateRange = JE.LocalDateRange;

  // chrono
  export const ChronoUnit = {
    days: J.ChronoUnit.DAYS,
    weeks: J.ChronoUnit.WEEKS,
    months: J.ChronoUnit.MONTHS,
  };

  export const DayOfWeek = J.DayOfWeek;
}

// types
export type JodaDateInput = J.LocalDate | J.LocalDateTime;
export type DateFormatter = (d: JodaDateInput) => string;
type OptionDateFormatter = (fallback: Lazy<string>) => (d: O.Option<JodaDateInput>) => string;

// constants
const timeZero = J.LocalTime.MIN;
export const localDateZero = J.LocalDate.parse("1970-01-01");
export const constLocalDateZero = constant(localDateZero);
export const localDateFuture = J.LocalDate.parse("3000-01-01");
export const constLocalDateFuture = constant(localDateFuture);

export const dateUnknownStr = "Date Unknown";
export const dateUnknownConst = constant(dateUnknownStr);
export const timeUnknownStr = "--:--";
export const dateTBD = "Date TBD";
export const dateTBDConst = constant(dateTBD);
export const localDateNow = () => J.LocalDate.now();
export const localDateTimeNow = () => J.LocalDateTime.now();
export const localDateTimeNowET = () => J.LocalDateTime.now(ET);

// converters
export const toLocalDateTime = (d: J.LocalDate) => d.atTime(timeZero);
export const toDate = (d: JodaDateInput) => J.convert(d).toDate();

export const isLocalDateToday = (ld: J.LocalDate) => ld === J.LocalDate.now();

const jodaErrorConstructor = (e: Error) => ({ _tag: "jodaError" as const, error: e });
const safeJoda = dangerousSafeCall(jodaErrorConstructor);

type UnsafeDateInput = number | string | Date;

export const parseUnsafeDateInput = (d: UnsafeDateInput): Rdr.Reader<BLConfigWithLog, E.Either<SafeCallError, J.LocalDateTime>> => {
  if (typeof d === "string") {
    return (c: BLConfigWithLog) => pipe(
      d,
      LocalDateC.decode,
      E.map(toLocalDateTime),
      E.orElse(() => LocalDateTimeFromIsoStringC.decode(d)),
      E.mapLeft((valErr) => ({
        error: valErr,
        _tag: "LocalDateC and LocalDateTimeFromIsoStringC failed to decode the provided string. Expected value is YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS.SSSZ",
      })),
      E.mapLeft(tap(e => c.log.error(e._tag, e.error)))
    );
  } else if (d instanceof Date) {
    return safeJoda((date: Date) => J.LocalDateTime.ofInstant(J.Instant.ofEpochMilli(date.getTime()), J.ZoneId.SYSTEM))(d);
  } else if (typeof d === "number") {
    return safeJoda((epochMilli: number) => J.LocalDateTime.ofInstant(J.Instant.ofEpochMilli(epochMilli), J.ZoneId.SYSTEM))(d);
  }
  return (c: BLConfigWithLog) => c.exhaustive(d);
};

const formatterFromPattern = (p: string) => J.DateTimeFormatter.ofPattern(p).withLocale(Locale.ENGLISH);
const formatLDT = (format: J.DateTimeFormatter) => (ldt: J.LocalDateTime) => ldt.format(format);
export const toLDT = (d: JodaDateInput) => d instanceof J.LocalDateTime ? d : toLocalDateTime(d);

const ldtFormatter = (pattern: string) => flow(toLDT, formatLDT(formatterFromPattern(pattern)));
const optionFormatter = (formatter: DateFormatter) => (fallback: Lazy<string>) => O.fold(fallback, formatter);

const humanDateFullFormat = "MM/dd/yyyy";
const timeFormat = "h:mm a";
const humanDateFullAtTimeFormat = `${humanDateFullFormat} 'at' ${timeFormat}`;
const weekOfFullDateFormat = `'Week of' ${humanDateFullFormat}`;

export const appendET = (s: string) => `${s} ET`;

export const isoDate: DateFormatter = ldtFormatter("yyyy-MM-dd");
export const humanDateFull: DateFormatter = ldtFormatter(humanDateFullFormat);
export const humanDateFullAtTime: DateFormatter = ldtFormatter(humanDateFullAtTimeFormat);
export const humanDateFullWithDay: DateFormatter = ldtFormatter(`eee ${humanDateFullFormat}`);
export const humanDateLong: DateFormatter = ldtFormatter("MMMM d, yyyy");
export const humanDateLongWithShortMonth: DateFormatter = ldtFormatter("MMM d, yyyy");
export const weekOfHumanFullDateFormat: DateFormatter = ldtFormatter(weekOfFullDateFormat);

export const shortDay: DateFormatter = ldtFormatter("eee");
export const shortMonth: DateFormatter = ldtFormatter("MMM");
export const monthAndDay: DateFormatter = ldtFormatter("M/d");
export const longMonth: DateFormatter = ldtFormatter("MMMM");
export const longMonthAndDay: DateFormatter = ldtFormatter("MMMM d");
export const shortMonthAndYear: DateFormatter = ldtFormatter("MMM yyyy");
export const fullMonthAndYear: DateFormatter = ldtFormatter("MMMM y");
export const quarter: DateFormatter = ldtFormatter("QQQ");
export const quarterWithYear: DateFormatter = ldtFormatter("QQQ yyyy");
export const year: DateFormatter = ldtFormatter("yyyy");
export const time: DateFormatter = ldtFormatter(timeFormat);

export const humanDateFullO: OptionDateFormatter = optionFormatter(humanDateFull);
export const humanDateFullAtTimeO: OptionDateFormatter = optionFormatter(humanDateFullAtTime);
export const fullMonthAndYearO: OptionDateFormatter = optionFormatter(fullMonthAndYear);
export const humanDateLongO: OptionDateFormatter = optionFormatter(humanDateLong);

// LocalDate from string
const localDateFromStringErr = (message: string, recieved: string) => new Error(`Error parsing ${message}. Recieved: ${recieved}`);
export const localDateFromQuarter = (q: string, y: number) => E.tryCatch(
  () => YearQuarter.parse(`${y}-${q}`).atDay(1),
  () => localDateFromStringErr("localDateFromQuarter", `q: ${q}, y: ${y}`)
);

export const localDateFromYear = (y: number) => E.tryCatch(
  () => J.LocalDate.of(y, 1, 1),
  () => localDateFromStringErr("localDateFromYear", `y: ${y}`)
);

export const localDateFromMonthName = (m: string, y: number) => E.tryCatch(
  () => J.LocalDate.parse(`${m} 1, ${y}`, formatterFromPattern("MMMM d, y")),
  () => localDateFromStringErr("localDateFromMonthName", `m: ${m}, y: ${y}`)
);

export const localDateFromWeekString = (w: string, y: number) => E.tryCatch(
  () => J.LocalDate.parse(`${w}/${y}`, formatterFromPattern("MM/d/y")),
  () => localDateFromStringErr("localDateFromWeekString", `w: ${w}, y: ${y}`)
);

// instance
export const instanceOfJodaLocalDate = (d: unknown): d is J.LocalDate => d instanceof J.LocalDate;

// util
export const constrainToLocalDateRange = (range: Joda.LocalDateRange) => (ld: J.LocalDate) => range.contains(ld)
  ? ld
  : range.isAfter(ld)
    ? range.start()
    : range.end();

export const LocalDateRangeEq: Eq<Joda.LocalDateRange> = {
  equals: (x, y) => x.equals(y),
};

export const daysDifferential = (from: Joda.LocalDate, to: Joda.LocalDate) =>
  from.until(to, Joda.ChronoUnit.days);

export const toSystemZone = (originalZone: J.ZoneId) => (ldt: Joda.LocalDateTime) => ldt.atZone(originalZone).withZoneSameInstant(J.ZoneId.SYSTEM).toLocalDateTime();
