import { type ReactElement, type ReactNode, useState } from "react";
import * as O from "fp-ts/Option";

import type { Markdown as MarkdownType } from "@scripts/codecs/markdown";
import { pipe, RNEA } from "@scripts/fp-ts";
import type { PageU } from "@scripts/generated/domaintables/pages";
import type { AttachedFaq } from "@scripts/generated/models/faq";
import type { PageConfig } from "@scripts/generated/models/pageConfig";
import type { RelatedFaqLink } from "@scripts/generated/models/relatedContent";
import type { HasManyLink, WithStatusU } from "@scripts/generated/models/threadThrough";
import { IconButton } from "@scripts/react/components/Button";
import { Markdown } from "@scripts/react/components/Markdown";
import type { Klass } from "@scripts/react/util/classnames";
import { klass, klassConditional } from "@scripts/react/util/classnames";
import * as jl from "@scripts/routes/routing/ssr/issuersitesJumpLinks";
import { allowQuestionSubmissions, type RfpWithSitesRelatedContent, showQuestionsAndAnswersSection } from "@scripts/syntax/rfp";

import chevron from "@svgs/chevron-down.svg";

import { mapOrEmpty, trueOrEmpty } from "./Empty";
import { AccentDividerSection, LayoutSection } from "./layout/Section";

type FaqBaseProps = {
  isOpen: boolean;
  klass: Klass;
  questionElement: ReactNode;
  answerElement: ReactNode;
  iconButton: ReactNode;
};

export const FaqBase = (props: FaqBaseProps) => (
  <div {...klass(props.klass, "faq-item")}>
    {props.iconButton}
    <div {...klass("faq-content")}>
      {props.questionElement}
      <div {...klass("expandable", O.filter(() => props.isOpen)(O.some("open")))}>
        {props.answerElement}
      </div>
    </div>
  </div >
);

type QandA = { question: string, answer: MarkdownType };
type FaqProps<A extends QandA> = {
  faq: A;
  klass: Klass;
};

export const Faq = <A extends QandA>(props: FaqProps<A>) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleFaqItem = () => setIsOpen(_ => !_);

  return <FaqBase
    klass={props.klass}
    isOpen={isOpen}
    questionElement={<h5 className="mt-0" onClick={toggleFaqItem}>{props.faq.question}</h5>}
    answerElement={<Markdown content={props.faq.answer} />}
    iconButton={<IconButton aria-label="toggle-question-and-answer" icon={chevron} onClick={toggleFaqItem} {...klassConditional("rotate", "rotate-ccw-180-svg")(isOpen)} />}
  />;
};

type RfpFaq = HasManyLink<WithStatusU<AttachedFaq>, RelatedFaqLink>;
export const RFPQuestionsAndAnswers = (props: {
  rfpData: RfpWithSitesRelatedContent;
  pages: ReadonlyArray<PageConfig<PageU>>;
  contactCallout: ReactElement;
}) => {
  const showSection = showQuestionsAndAnswersSection(props.rfpData.rfp, props.rfpData.relatedContent.faqs);

  return trueOrEmpty(<AccentDividerSection
    jumpLink={jl.rfp.questionsAndAnswers(props.rfpData)}
    pages={props.pages}
    headerDivider
  >
    {pipe(
      props.rfpData.relatedContent.faqs,
      RNEA.fromReadonlyArray,
      mapOrEmpty((q: ReadonlyArray<RfpFaq>) => <LayoutSection title={O.none}>
        {q.map(faq => <Faq
          key={faq.data.data.id}
          faq={faq.data.data.record}
          klass={O.none}
        />)}
      </LayoutSection>)
    )}
    {pipe(
      props.rfpData.rfp,
      allowQuestionSubmissions,
      trueOrEmpty(<LayoutSection title={O.some("Have a question?")}>
        <p>Contact us if you need more information or have questions about this RFP.</p>
        {props.contactCallout}
      </LayoutSection>)
    )}
  </AccentDividerSection>)(showSection);
};
