export * from "fp-ts/lib/Record";
import * as R from "fp-ts/lib/Record";

import type * as O from "./Option";
import { type Ord, preserve } from "./Ord";

export const modsertAt = <A>(k: string, f: (a: A) => A, fallback: A) => (r: Record<string, A>) =>
  R.upsertAt(k, f(r[k] ?? fallback))(r);

export const countKeys = <K extends string>(a: Record<K, number>, keys: K[]) => keys.reduce(
  (acc: Record<K, number>, k) => modsertAt(k, i => i + 1, 0)(acc),
  a,
);

export type NonEmptyRecord<A, K extends keyof A = keyof A> =
  A extends Record<K, A[K]>
    ? K extends never
      ? never
      : A
    : never;

export type NonNullableKeys<P> = Exclude<{
  [K in keyof P]: P[K] extends NonNullable<unknown>
    ? K
    : never;
}[keyof P], undefined>;

export type NullableKeys<P> = Exclude<{
  [K in keyof P]: P[K] extends NonNullable<unknown>
    ? never
    : K
}[keyof P], undefined>;

export type OptionalKeys<P> = Exclude<{
  [K in keyof P]: P[K] extends O.Option<unknown>
    ? K
    : never
}[keyof P], undefined>;

export type NonNullableFields<P> = Pick<P, NonNullableKeys<P>> & Partial<Record<NullableKeys<P>, never>>;
export type NullableFields<P> = Pick<P, NullableKeys<P>>;
export type OptionalFields<P> = Pick<P, OptionalKeys<P>>;

export type PartiallyPartialize<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type NullableToOptionalFields<P> = { [K in keyof P]-?: P[K] extends NonNullable<unknown> ? P[K] : O.Option<NonNullable<P[K]>>; };

export const toEntriesWithOrd = (ord: Ord<string>): <K extends string, A>(r: Record<K, A>) => Array<[K, A]> => R.collect(ord)(
  (k, a) => [k, a]
);

export const toEntriesNoSort: <K extends string, A>(r: Record<K, A>) => Array<[K, A]> = toEntriesWithOrd(preserve());
