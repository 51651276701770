import type {
  ChangeEvent,
  Dispatch,
  FormEvent,
  ReactNode,
  Ref,
  SetStateAction,
  SyntheticEvent,
} from "react";
import {
  useTransition,
} from "react";
import * as b from "fp-ts/lib/boolean";
import type { Either } from "fp-ts/lib/Either";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { flow, identity, pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import type * as TE from "fp-ts/lib/TaskEither";
import * as Th from "fp-ts/lib/These";
import { useStableEffect, useStableO } from "fp-ts-react-stable-hooks";
import * as t from "io-ts";
import { Index, Lens, Optional } from "monocle-ts";
import { atRecord } from "monocle-ts/lib/At/Record"; // eslint-disable-line @typescript-eslint/no-restricted-imports

import type { ApiData, BLRequestInit } from "@scripts/api/methods";
import { apiFetchWithCredResp } from "@scripts/api/methods";
import { responseErrorHandler } from "@scripts/api/responseErrorHandler";
import { bigNumberC } from "@scripts/Big";
import { htmlC } from "@scripts/codecs/html";
import { LocalDateC, LocalDateTimeFromIsoStringC } from "@scripts/codecs/localDate";
import { markdownC } from "@scripts/codecs/markdown";
import type { NonEmptyArrayType } from "@scripts/codecs/nonEmptyArray";
import type { ReadonlyNonEmptyArrayType } from "@scripts/codecs/readonlyNonEmptyArray";
import type { MaybeRecursive, RecursiveRecord } from "@scripts/codecs/recursiveArrayRecord";
import type { RespOrErrors } from "@scripts/fetch";
import { O, RR, s } from "@scripts/fp-ts";
import type { Primitive } from "@scripts/fp-ts/lib/types/matchers";
import type { Option } from "@scripts/fp-ts/Option";
import type { ReadonlyRecord } from "@scripts/fp-ts/ReadonlyRecord";
import { run } from "@scripts/fp-ts/TaskEither";
import { dayToDayC } from "@scripts/generated/domaintables/dateQualifiers";
import { PageCU } from "@scripts/generated/domaintables/pages";
import * as rc from "@scripts/generated/domaintables/responseCodes";
import { ActorIdCU, userIdC } from "@scripts/generated/models/actor";
import { dateQualifierC } from "@scripts/generated/models/dateQualifier";
import { timeC } from "@scripts/generated/models/rfpBase";
import { type Codec } from "@scripts/react/form/codecs";
import type { ServerValidationError } from "@scripts/react/form/responseCodecs";
import * as respCodecs from "@scripts/react/form/responseCodecs";
import { existingErrors } from "@scripts/react/form/responseCodecs";
import type { UrlInterfaceIO } from "@scripts/routes/urlInterface";
import type { DeepPartialWithOptions } from "@scripts/types/deepPartialWithOptions";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";
import type { LogErrors } from "@scripts/util/log";
import { logErrors } from "@scripts/util/log";
import { tap } from "@scripts/util/tap";

import type { BLConfigWithLog } from "../../bondlink";
import { useConfig } from "../context/Config";
import type { ErrorTextContext, ValErrMsgFn } from "./errors";
import { validationErrorMessage } from "./errors";

export type DataCodecA<A extends t.Props> = t.TypeC<A>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataCodec = DataCodecA<any>;
export type ResponseCodec = t.Mixed;
export type ReadonlyNonEmptyArrayCodec = ReadonlyNonEmptyArrayType<t.Mixed>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NonEmptyArrayCodec = NonEmptyArrayType<any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReadonlyArrayCodec = t.ReadonlyArrayC<any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ArrayCodec = t.ArrayC<any>;


export type FormValidationErrors<P extends t.Props> = t.PartialType<P, {
  [K in keyof P]?:
  P[K] extends DataCodec
  ? ValidationErrors<P[K]>
  : P[K] extends NonEmptyArrayCodec | ReadonlyNonEmptyArrayCodec | ArrayCodec | ReadonlyArrayCodec
  ? ReadonlyRecord<string, ValidationErrors<P[K]["type"]>> | ReadonlyArray<ValidationErrors<P[K]["type"]>>
  : t.TypeOf<typeof respCodecs.serverValidationErrorsAC>;
}, {
  [K in keyof P]?:
  P[K] extends DataCodec
  ? ValidationErrors<P[K]>
  : P[K] extends NonEmptyArrayCodec | ReadonlyNonEmptyArrayCodec | ArrayCodec | ReadonlyArrayCodec
  ? ReadonlyRecord<string, ValidationErrors<P[K]["type"]>> | ReadonlyArray<ValidationErrors<P[K]["type"]>>
  : t.OutputOf<typeof respCodecs.serverValidationErrorsAC>;
  }>;

const immutableCodecs = [
  dateQualifierC,
  dayToDayC,
  LocalDateC,
  LocalDateTimeFromIsoStringC,
  markdownC,
  htmlC,
  ActorIdCU, // JDL-TODO remove some of these in favor of using Exclude in UnsafeFormData
  userIdC,
  PageCU,
  bigNumberC,
  timeC,
] as const;
export type ExcludedTypes = t.TypeOf<typeof immutableCodecs[number]>;

type _ValidationErrors<T> =
  T extends Primitive | ExcludedTypes
  ? respCodecs.ServerValidationErrorsA
  : [T] extends [O.Option<infer S>]
  ? ValidationErrors<S>
  // We only need to check ReadonlyArray instead of ReadonlyNonEmptyArray, NonEmptyArray, ReadonlyArray, and Array
  // individually since they all variants return the same underlying type from the server and ReadonlyArray is the
  // lowest common denominator of all the array types. - JDL
  : [T] extends [ReadonlyArray<infer U>]
  ? ReadonlyRecord<string, ValidationErrors<U>> | ReadonlyArray<ValidationErrors<U>>
  : { [P in keyof T]: ValidationErrors<T[P]> };


export type ValidationErrors<T> =
  DeepPartialWithOptions<_ValidationErrors<T>, ExcludedTypes | respCodecs.ServerValidationErrorsA>;

export type UnsafeFormData<PC extends t.Mixed, Exclude = never> =
  DeepPartialWithOptions<t.TypeOf<PC>, ExcludedTypes | Exclude>;
export type UnsafeFormDataNoCodec<PC, Exclude = never> =
  DeepPartialWithOptions<PC, ExcludedTypes | Exclude>;
export type UnsafeDataLens<PC extends DataCodec, KV> = Lens<UnsafeFormData<PC>, KV>;

export type UnsafeFormDataMixed<PC extends t.Mixed> = DeepPartialWithOptions<t.TypeOf<PC>, ExcludedTypes>;

export type UnsafeFormProp<A> = A | undefined;

interface FormData<PC extends DataCodec> {
  data: UnsafeFormData<PC>;
}

export interface FormErrors<PC extends DataCodec> {
  validationErrors: ValidationErrors<t.TypeOf<PC>>;
  errors: respCodecs.ResponseErrors["errors"];
}

export type FormStateBase = {
  loading: boolean;
  modified: boolean; // initially false, becomes true once the user has begun editing
};

export type FormProcessor<PC extends DataCodec> = (state: FormState<PC>) => FormState<PC>;
export type FormState<PC extends DataCodec> = FormData<PC> & FormErrors<PC> & FormStateBase & {
  processors: Record<string, FormProcessor<PC>>;
  immutableCodecs: typeof immutableCodecs; // Used for setting codecs that can not have partial values such as dateQualifierC
};

export const emptyFormState = <PC extends DataCodec = never>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  data: UnsafeFormData<PC> = ({} as UnsafeFormData<PC>),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  validationErrors: ValidationErrors<t.TypeOf<PC>> = ({} as ValidationErrors<t.TypeOf<PC>>)
): FormState<PC> => ({
  data: data,
  validationErrors: validationErrors,
  loading: false,
  modified: false,
  errors: [],
  processors: {},
  immutableCodecs: immutableCodecs,
});


export type FormStateLens<PC extends DataCodec, QC extends DataCodec> = {
  outerLens: UnsafeDataLens<PC, ReadonlyArray<UnsafeFormData<QC>>>;
  state: FormState<PC>;
  setState: Dispatch<SetStateAction<FormState<PC>>>;
};

export type FormLens<PC extends DataCodec> = <K extends keyof UnsafeFormData<PC>>(k: K) =>
  Lens<UnsafeFormData<PC>, UnsafeFormData<PC>[K]>;

export type FormLensNoCodec<PC> = <K extends keyof UnsafeFormDataNoCodec<PC>>(k: K) =>
  Lens<UnsafeFormDataNoCodec<PC>, UnsafeFormDataNoCodec<PC>[K]>;

export type FormLensNonNullable<PC extends DataCodec> = <K extends keyof UnsafeFormData<PC>>(k: K, defaultValue: NoInfer<NonNullable<UnsafeFormData<PC>[K]>>) =>
  Lens<UnsafeFormData<PC>, NonNullable<UnsafeFormData<PC>[K]>>;

export type FormDataLens<PC extends DataCodec> = Lens<FormState<PC>, FormState<PC>["data"]>;

export type FormSuccessAction<PC extends DataCodec, RC extends ResponseCodec> = (d: FormState<PC>, a: ApiData<t.TypeOf<RC>>) => void;
interface FormMeta<PC extends DataCodec, RC extends ResponseCodec> {
  url: UrlInterfaceIO<"POST", PC, RC>;
  state: FormState<PC>;
  setState: Dispatch<SetStateAction<FormState<PC>>>;
  onSuccess: FormSuccessAction<PC, RC>;
  onFailure: Option<(e: FormState<PC>) => void>;
  headers: Option<HeadersInit>;
}

export type BeforeSubmit<PC extends DataCodec> =
  (state: FormState<PC>) => TE.TaskEither<respCodecs.ResponseErrors["errors"], FormState<PC>>;

export type FormProps<PC extends DataCodec, RC extends ResponseCodec> = FormMeta<PC, RC> & {
  beforeSubmit?: BeforeSubmit<PC>;
  children: ReactNode;
  formRef?: Ref<HTMLFormElement>;
  errorOverride?: boolean;
};

export const createValidationErrorMsg = (config: BLConfigWithLog) =>
  (ve: respCodecs.ServerValidationError, label: Option<string>) =>
    validationErrorMessage(config)(ve)(O.none, label);

export const formLens = <PC extends DataCodec>(): FormLens<PC> =>
  Lens.fromProp<UnsafeFormData<PC>>();
export const formNonNullableLens = <PC extends DataCodec>(): FormLensNonNullable<PC> =>
  Lens.fromNullableProp<UnsafeFormData<PC>>();
export const formDataLens = <PC extends DataCodec>(): FormDataLens<PC> =>
  Lens.fromProp<FormState<PC>>()("data");
export const formErrorsLens = <PC extends DataCodec>(): Lens<FormState<PC>, respCodecs.ResponseErrors["errors"]> =>
  Lens.fromProp<FormState<PC>>()("errors");
export const formModifiedLens = <PC extends DataCodec>(): Lens<FormState<PC>, boolean> =>
  Lens.fromProp<FormState<PC>>()("modified");
export const formLoadingLens = <PC extends DataCodec>(): Lens<FormState<PC>, boolean> =>
  Lens.fromProp<FormState<PC>>()("loading");
export const formProcessorsLens = <PC extends DataCodec>(): Lens<FormState<PC>, Record<string, FormProcessor<PC>>> =>
  Lens.fromProp<FormState<PC>>()("processors");
export const formValidationErrorsLens = <PC extends DataCodec>(): Lens<FormState<PC>, ValidationErrors<t.TypeOf<PC>>> =>
  Lens.fromProp<FormState<PC>>()("validationErrors"); // TODO: make this type actually use correct types
export function hasValidationErrors<PC extends DataCodec>(ves: ValidationErrors<t.TypeOf<PC>>): boolean {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return ves == null ? false : Object.values(ves as unknown as respCodecs.ServerValidationErrorsA).some(ve =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ve != null
    && typeof ve === "object"
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    && ((respCodecs.serverValidationErrorsAC.is(ve) && RA.isNonEmpty(ve)) || hasValidationErrors(ve as unknown as ValidationErrors<t.TypeOf<PC>>))
  );
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const defaultItem = <PC extends DataCodec>(): UnsafeFormData<PC> => ({}) as UnsafeFormData<PC>;

const formArrayOptional = <PC extends DataCodec>(i: number): Optional<ReadonlyArray<UnsafeFormData<PC>>, UnsafeFormData<PC>> =>
  new Optional<ReadonlyArray<UnsafeFormData<PC>>, UnsafeFormData<PC>>(c => O.fromNullable(c[i]), a => c => RA.unsafeUpdateAt(i, a, c));

const formIDX = <PC extends DataCodec>(): Index<ReadonlyArray<UnsafeFormData<PC>>, number, UnsafeFormData<PC>> =>
  new Index<ReadonlyArray<UnsafeFormData<PC>>, number, UnsafeFormData<PC>>(formArrayOptional<PC>);

export const formIndexL = <PC extends DataCodec>(i: number): Lens<ReadonlyArray<UnsafeFormData<PC>>, UnsafeFormData<PC>> =>
  new Lens(c => O.toUndefined(formIDX<PC>().index(i).getOption(c)) ?? defaultItem<PC>(), formIDX<PC>().index(i).set);

export const formIndexNullableL = <PC extends DataCodec>(i: number): Lens<ReadonlyArray<UnsafeFormData<PC>>, UnsafeFormData<PC> | undefined> =>
  new Lens(
    (c: ReadonlyArray<UnsafeFormData<PC>>): UnsafeFormData<PC> | undefined => O.toUndefined(formIDX<PC>().index(i).getOption(c)),
    c => formIDX<PC>().index(i).set(c ?? defaultItem<PC>())
  );

const formArrayPrimitiveOptional = <PC extends t.Mixed>(i: number): Optional<UnsafeFormData<t.ReadonlyArrayC<PC>>, UnsafeFormData<PC>> =>
  new Optional<UnsafeFormData<t.ReadonlyArrayC<PC>>, UnsafeFormData<PC>>(c => O.fromNullable(c && c[i]), a => c => RA.unsafeUpdateAt(i, a, c ?? []));

const formIDXPrimitive = <PC extends t.Mixed>(): Index<UnsafeFormData<t.ReadonlyArrayC<PC>>, number, UnsafeFormData<PC>> =>
  new Index(formArrayPrimitiveOptional<PC>);

export const formIndexPrimitiveL = <PC extends t.Mixed>(i: number, defaultOption: NoInfer<UnsafeFormData<PC>>): Lens<UnsafeFormData<t.ReadonlyArrayC<PC>>, UnsafeFormData<PC>> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  new Lens(c => O.toUndefined(formIDXPrimitive<PC>().index(i).getOption(c)) ?? defaultOption, formIDXPrimitive<PC>().index(i).set);

export const addProcessor = <PC extends DataCodec>(setState: Dispatch<SetStateAction<FormState<PC>>>) =>
  (key: string, processor: FormProcessor<PC>): void =>
    setState((state) =>
      Lens.fromProp<FormState<PC>>()("processors").compose(
        atRecord<FormProcessor<PC>>().at(key)
      ).modify(() => O.some(processor))(state)
    );

type SetState<S> = Dispatch<SetStateAction<S>>;

const onFailure = <PC extends DataCodec>(failureEffect: Option<(e: FormState<PC>) => void>) =>
  <K extends keyof FormState<PC>>(...props: K[]) =>
    (values: Pick<FormState<PC>, K>) =>
      (setState: SetState<FormState<PC>>): void => {
        const lens = Lens.fromProps<FormState<PC>>();

        setState(flow(
          lens(props).set(values),
          formLoadingLens<PC>().set(false),
          // setTimeout here is to delay any successEffect in case it queues a state update in another component
          // https://github.com/facebook/react/issues/18178#issuecomment-602323184
          tap((st) => pipe(failureEffect, O.map(fe => globalThis.setTimeout(() => fe(st), 1))))
        ));
      };

const codecError = (e: LogErrors, u: unknown): ReadonlyArray<respCodecs.ResponseError> => {
  logErrors(e, u);
  return [{ error: respCodecs.CODEC_ERROR, field: "", extra: "" }];
};


export const handleErrors = <PC extends DataCodec>(codec: PC) => (failureEffect: Option<(e: FormState<PC>) => void>) =>
  (state: FormState<PC>, setState: SetState<FormState<PC>>) =>
    (or: Option<Response>): void => {
      const handleFailure = onFailure(failureEffect);
      const handleNetworkError = () => handleFailure("errors")({ errors: [{ error: respCodecs.NETWORK_ERROR, extra: "", field: "" }] })(setState);
      const handleResponseTextError = () => handleFailure("errors")({ errors: [{ error: respCodecs.RESPONSE_TEXT_ERROR, extra: "", field: "" }] })(setState);
      const handleParsingJsonError = (e: LogErrors, str: string) => handleFailure("errors")({ errors: codecError(e, str) })(setState);
      const handleErrorResponse = (a: unknown) => {
        if (respCodecs.responseErrorsC.is(a)) {
          pipe(
            respCodecs.responseErrorsC.decode(a),
            E.bimap(
              (e: t.Errors) => handleFailure("errors")({
                errors: codecError([
                  { level: "fatal", message: "Attempted to decode responseErrors", errors: e },
                ], a),
              })(setState),
              (e: respCodecs.ResponseErrors) => handleFailure("errors")({ errors: e.errors })(setState)
            )
          );
        } else {
          pipe(
            // TODO: Better typing here
            respCodecs.formValidationErrorsC(codec, Array.from(state.immutableCodecs)),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
            (vC) => vC.decode((a as any).validationErrors) as unknown as t.Validation<ValidationErrors<t.TypeOf<PC>>>,
            E.bimap(
              (e: t.Errors) => {
                handleFailure("errors")({
                  errors: codecError([
                    { level: "fatal", message: "Attempted to decode formValidationErrors", errors: e },
                  ], a),
                })(setState);
              },
              (e: ValidationErrors<t.TypeOf<PC>>) =>
                handleFailure("errors", "validationErrors")({
                  errors: [{ error: rc.FIELD_ERRORS, extra: "", field: "" }],
                  validationErrors: e,
                })(setState)
            )
          );
        }
      };
      responseErrorHandler(or)(handleNetworkError, handleResponseTextError, handleParsingJsonError, handleErrorResponse);
    };

const initializeRequest: (headers: Option<HeadersInit>) => BLRequestInit = flow(O.map(RR.unit(s.literal("headers"))), O.orElse({}));

const onSuccess = <PC extends DataCodec, RC extends ResponseCodec>(successEffect: FormSuccessAction<PC, RC>) =>
  (a: ApiData<t.TypeOf<RC>>) =>
    (setState: SetState<FormState<PC>>): void => setState(flow(
      formLoadingLens<PC>().set(false),
      formModifiedLens<PC>().set(false),
      // setTimeout here is to delay any successEffect in case it queues a state update in another component
      // https://github.com/facebook/react/issues/18178#issuecomment-602323184
      tap(st => globalThis.setTimeout(() => successEffect(st, a), 1))
    ));

export const handleRespOrErrors = <PC extends DataCodec, RC extends ResponseCodec>(
  meta: Pick<FormMeta<PC, RC>, "setState" | "onFailure"> & { input: FormMeta<PC, RC>["url"]["input"] }
) => (state: FormState<PC>): (re: RespOrErrors) => Th.These<void, void> =>
    Th.bimap(
      handleErrors(meta.input)(meta.onFailure)(state, meta.setState),
      (e: LogErrors) => onFailure(meta.onFailure)("errors")({ errors: codecError(e, state.data) })(meta.setState)
    );

export const onSubmit =
  (config: BLConfigWithLog) =>
    <PC extends DataCodec, RC extends ResponseCodec>(meta: FormMeta<PC, RC>) =>
      (event: FormEvent<HTMLFormElement> | SyntheticEvent<Element>): void => {
        event.preventDefault();
        event.stopPropagation();
        const lens = Lens.fromProp<FormState<PC>>();
        const handleFailure = onFailure(meta.onFailure)("errors")(existingErrors());
        const formCodec = respCodecs.deepPartialC(meta.url.input, Array.from(meta.state.immutableCodecs));
        const handleSuccess = onSuccess(meta.onSuccess);

        meta.setState(formLoadingLens<PC>().set(true));
        pipe(
          meta.state,
          (st: FormState<PC>) => Object.values(st.processors).reduce((prevState: FormState<PC>, fn: FormProcessor<PC>) => fn(prevState), st),
          (st: FormState<PC>): E.Either<FormState<PC>, FormState<PC>> => hasValidationErrors(st.validationErrors)
            ? E.left(st)
            : E.right(lens("errors").set([])(st)),
          E.bimap(
            () => handleFailure(meta.setState),
            st => {
              const task = apiFetchWithCredResp(config)({ ...meta.url, input: formCodec }, initializeRequest(meta.headers))(st.data)(
                handleRespOrErrors({ ...meta, input: meta.url.input })(st),
                (a) => handleSuccess(a)(meta.setState)
              );
              // TODO: Fold the containing `Either` into a `Task` that the user can call, rather than executing this side-effect inline
              void run(task);
            }
          )
        );
      };


export type StateProps<PC extends DataCodec, KV, KVLinked = unknown> = {
  state: FormState<PC>;
  setState: Dispatch<SetStateAction<FormState<PC>>>;
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>;
  linked?: UnsafeDataLens<PC, KVLinked>;
};

const PARSE_VALUE_ERROR_MSG = "Input is not the correct type";

export type ParseValue<KV> = Either<respCodecs.ServerValidationErrorsA, KV>;
export const parseValue = <KV>(codec: Codec<KV>) => (val: unknown): ParseValue<KV> =>
  pipe(
    codec.decode(val),
    E.mapLeft(
      () => {
        return [{
          error: { _tag: respCodecs.FORM_CODEC_ERROR._tag, val: (typeof val !== "string" || (Array.isArray(val) && val.length > 0)) ? O.some(val) : O.none },
          extra: O.some(PARSE_VALUE_ERROR_MSG),
        }];
      }
    )
  );

export const coerceLensAsValidationLens = <PC extends DataCodec, KV>(lens: UnsafeDataLens<PC, KV>):
  Lens<ValidationErrors<t.TypeOf<PC>>, ValidationErrors<KV> | respCodecs.ServerValidationErrorsA> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  lens as unknown as Lens<ValidationErrors<t.TypeOf<PC>>, ValidationErrors<KV> | respCodecs.ServerValidationErrorsA>;

export const coerceOptionalAsValidationLens = <PC extends DataCodec, KV>(lens: Optional<UnsafeFormData<PC>, KV>):
  Optional<ValidationErrors<t.TypeOf<PC>>, respCodecs.ServerValidationErrorsA> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  lens as unknown as Optional<ValidationErrors<t.TypeOf<PC>>, respCodecs.ServerValidationErrorsA>;

export const coerceUndefinedAsValidationErrors = <KV>(): ValidationErrors<KV> | respCodecs.ServerValidationErrorsA =>
  // eslint-disable-next-line no-undefined, @typescript-eslint/consistent-type-assertions
  undefined as unknown as ValidationErrors<KV> | respCodecs.ServerValidationErrorsA;

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function coerceUndefinedAsType<A>(): A {
  // eslint-disable-next-line no-undefined, @typescript-eslint/consistent-type-assertions
  return undefined as unknown as A;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function coerceEmptyArrayAsType<A>(): A {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return [] as unknown as A;
}

export const isModified = (...states: ReadonlyArray<FormState<DataCodec>>) =>
  RA.some<FormState<DataCodec>>((state) => state.modified)(states);

export const formModified = <PC extends DataCodec>(modified: boolean) =>
  (state: FormState<PC>): FormState<PC> =>
    formModifiedLens<PC>().set(modified)(state);

export const resetFormModifiedAndSetState = <PC extends DataCodec>(
  setState: Dispatch<SetStateAction<FormState<PC>>>): void =>
  setState(formModified(false));

const clearResponseErrors = <PC extends DataCodec>(
  state: FormState<PC>): FormState<PC> =>
  formErrorsLens<PC>().set([])(state);

export const clearValidationErrors = <PC extends DataCodec, KV>(
  lens: UnsafeDataLens<PC, KV>) =>
  (state: FormState<PC>): FormState<PC> => {
    const s1 = formValidationErrorsLens<PC>()
      .compose(coerceLensAsValidationLens(lens))
      .set(coerceUndefinedAsValidationErrors<KV>())(state);
    return pipe(
      s1,
      hasValidationErrors(s1.validationErrors)
        ? identity
        : clearResponseErrors
    );
  };

export const clearState = <PC extends DataCodec, KV>(lens: UnsafeDataLens<PC, KV>) =>
  (state: FormState<PC>, setModifiedState: boolean = true): FormState<PC> =>
    pipe(
      formDataLens<PC>().compose(lens).set(coerceUndefinedAsType<KV>())(state),
      formModified(setModifiedState),
      clearValidationErrors(lens)
    );

export const clearAndSetState = <PC extends DataCodec, KV>(
  setState: Dispatch<SetStateAction<FormState<PC>>>,
  lens: UnsafeDataLens<PC, KV>,
  setModifiedState: boolean = true
): void =>
  setState(_ => clearState(lens)(_, setModifiedState));

export const modifyState = <PC extends DataCodec, KV>(lens: UnsafeDataLens<PC, KV>) =>
  (fn: (kv: KV) => KV) =>
    (state: FormState<PC>): FormState<PC> =>
      pipe(
        state,
        formDataLens<PC>().compose(lens).modify(fn),
        formModified(true),
        clearValidationErrors(lens),
      );

export const modifyAndSetState = <PC extends DataCodec, KV>(
  setState: Dispatch<SetStateAction<FormState<PC>>>,
  lens: UnsafeDataLens<PC, KV>) =>
  (fn: (kv: KV) => KV): void =>
    setState(modifyState(lens)(fn));

export const updateState = <PC extends DataCodec, KV>(lens: UnsafeDataLens<PC, KV>) =>
  (kv: KV, setModifiedState: boolean = true) =>
    (state: FormState<PC>): FormState<PC> =>
      pipe(
        state,
        formDataLens<PC>().compose(lens).set(kv),
        formModified(setModifiedState),
        clearValidationErrors(lens),
      );

const clearLinked = <PC extends DataCodec, KVLinked = unknown>(linked?: UnsafeDataLens<PC, KVLinked>) => pipe(O.fromNullable(linked), O.fold(() => identity<FormState<PC>>, clearValidationErrors));

export const updateAndSetState = <PC extends DataCodec, KV, KVLinked = unknown>(
  setState: Dispatch<SetStateAction<FormState<PC>>>,
  lens: UnsafeDataLens<PC, KV>,
  linked?: UnsafeDataLens<PC, KVLinked>
) => (kv: KV, setModifiedState: boolean = true): void =>
    setState(flow(updateState(lens)(kv, setModifiedState), clearLinked(linked)));

export const updateStateViaParsed = <PC extends DataCodec, KV, KVLinked = unknown>(
  lens: UnsafeDataLens<PC, KV>,
  codec: Codec<KV>,
  linked?: UnsafeDataLens<PC, KVLinked>
) =>
  (a: ParseValue<KV>, setModifiedState: boolean = true) => (state: FormState<PC>): FormState<PC> => E.fold(
    (es: ValidationErrors<KV> | respCodecs.ServerValidationErrorsA) =>
      formValidationErrorsLens<PC>()
        .compose(coerceLensAsValidationLens(lens))
        .set(es)(state),
    (v: KV) => pipe(codec.encode(v), O.fold(() => clearState(lens)(state, setModifiedState), () => updateState(lens)(v, setModifiedState)(state)), clearLinked(linked))
  )(a);

export const updateStateViaCodec = <PC extends DataCodec, KV, KVLinked = unknown>(
  lens: UnsafeDataLens<PC, KV>,
  codec: Codec<KV>,
  setModifiedState: boolean = true,
  linked?: UnsafeDataLens<PC, KVLinked>
) =>
  (val: unknown) =>
    (state: FormState<PC>): FormState<PC> =>
      updateStateViaParsed(lens, codec, linked)(parseValue(codec)(val), setModifiedState)(state);

export const updateAndSetStateViaCodec = <PC extends DataCodec, KV, KVLinked = unknown>(
  setState: Dispatch<SetStateAction<FormState<PC>>>,
  lens: UnsafeDataLens<PC, KV>,
  codec: Codec<KV>,
  setModifiedState: boolean = true,
  linked?: UnsafeDataLens<PC, KVLinked>
) =>
  (val: unknown): void =>
    setState(updateStateViaCodec(lens, codec, setModifiedState, linked)(val));

const useTransitionSetup = <KV>(stateValue: O.Option<KV>, eq: Eq.Eq<KV>) => {
  const [localValue, setLocalValue] = useStableO<KV>(stateValue);
  const [isPending, startTransition] = useTransition();

  useStableEffect(() => {
    if (!isPending && !O.getEq(eq).equals(stateValue, localValue)) {
      setLocalValue(stateValue);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPending, stateValue],
    Eq.tuple(b.Eq, O.getEq(eq))
  );

  return [localValue, setLocalValue, startTransition] as const;
};

export const useUpdateAndSetStateTransition = <KV>(
  stateValue: O.Option<KV>,
  eq: Eq.Eq<KV>,
) => {
  const [localValue, setLocalValue, startTransition] = useTransitionSetup(stateValue, eq);

  const updateFn = <PC extends DataCodec, KVLinked = unknown>(
    setState: Dispatch<SetStateAction<FormState<PC>>>,
    lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>,
    linked?: UnsafeDataLens<PC, KVLinked>
  ) =>
    (kv: KV, setModifiedState: boolean = true): void => {
      setLocalValue(O.some(kv));
      startTransition(() => updateAndSetState(setState, lens, linked)(kv, setModifiedState));
    };

  return [localValue, updateFn] as const;
};

export const useVeOnChangeTransition = <PC extends DataCodec, KV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>,
  codec: Codec<KV>,
  errorTextContext?: ErrorTextContext
) => {
  const config = useConfig();
  const ve = getValErrViaCodec(config)(state, lens, codec, errorTextContext);
  const [localValue, setLocalValue] = useUpdateAndSetStateOnChange(ve.val);
  return [ve, localValue, setLocalValue] as const;
};

export const useUpdateAndSetStateViaCodecTransition = <LKV>(
  stateValue: Option<LKV>,
  eq: Eq.Eq<LKV>,
) => {
  const [localValue, setLocalValue, startTransition] = useTransitionSetup(stateValue, eq);
  const updateFn = <PC extends DataCodec, KV, KVLinked = unknown>(
    setState: Dispatch<SetStateAction<FormState<PC>>>,
    lens: UnsafeDataLens<PC, KV>,
    codec: Codec<KV>,
    linked?: UnsafeDataLens<PC, KVLinked>,
    setModifiedState: boolean = true,
  ) =>
    (val: LKV): void => {
      // Set localValue to O.none if val encodes to O.none, else set localValue to O.some(val) -- This is similar behavior to updateStateViaCodec
      setLocalValue(pipe(O.fromEither(codec.decode(val)), O.filterMap(codec.encode), O.map(() => val)));
      startTransition(() => updateAndSetStateViaCodec(setState, lens, codec, setModifiedState, linked)(val));
    };

  return [localValue, updateFn] as const;
};

export type UpdateStateViaCodecTransitionFn<LKV> = ReturnType<typeof useUpdateAndSetStateViaCodecTransition<LKV>>[1];

export type UpdateAndSetStateOnChange = <PC extends DataCodec, KV, CE extends HTMLInputElement | HTMLTextAreaElement>(
  setState: Dispatch<SetStateAction<FormState<PC>>>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>,
  codec: Codec<KV>
) => (e: ChangeEvent<CE>) => void;

export const useUpdateAndSetStateOnChange = (
  stateValue: Option<string>,
): readonly [O.Option<string>, UpdateAndSetStateOnChange] => {
  const [localValue, updateStateTransition] = useUpdateAndSetStateViaCodecTransition(
    stateValue,
    s.Eq
  );

  const updateFn = <PC extends DataCodec, KV>(
    setState: Dispatch<SetStateAction<FormState<PC>>>,
    lens: UnsafeDataLens<PC, KV>,
    codec: Codec<KV>
  ) =>
    <T extends Element & { value: string }>(e: ChangeEvent<T>): void => {
      updateStateTransition(setState, lens, codec)(e.target.value);
    };

  return [localValue, updateFn] as const;
};

export type ValErrsRecU = MaybeRecursive<ServerValidationError>;

export const serverValidationErrorsARC = t.union([respCodecs.serverValidationErrorsAC, respCodecs.serverValidationErrorsRC]);
export type ServerValidationErrorsAR = t.TypeOf<typeof serverValidationErrorsARC> | Record<number, ValErrsRecU>;

/*
* If there was an error such as the whole array and/or key where the array was expected by the server was missing, expect a ReadonlyArray<ValErrMsg>.
* If there was an error within a valid array of post data then expect a Record<number, ReadonlyArray<ValErrMsg>>,
* where the number key is the index of the array data that was posted. - JDL
*
* There is also the possiblilty of an error within the body of an object in a valid array of post data, such as in the "URL" field in an attached link.
* This means we could have a ReadonlyArray<ValErrMsg> nested at any depth within a Record, so we will have to handle recursively mapping through the record. - KS
*/
export type ValErrMsgA = ReadonlyArray<ValErrMsgFn>;
export type ValErrMsgR = RecursiveRecord<ValErrMsgFn>;
export type ValErrMsgAR<A> = E.Either<ValidationErrors<A>, ValErrMsgA>;
type ValErrBase<A, KV = A> = { val: Option<NonNullable<A>>, err: ValErrMsgAR<KV> };
export type ValErr<A> = ValErrBase<A>;
export type ValErrViaCodec<KV> = ValErrBase<string, UnsafeFormProp<KV>>;

export const isValErrA = (item: MaybeRecursive<ValErrMsgFn>): item is ValErrMsgA =>
  Array.isArray(item) && item.every(a => typeof a === "function");
export const flattenValErr = <A>(v: ValErrMsgAR<A>): ValErrMsgA => pipe(v, O.fromEither, O.getOrElse((): ValErrMsgA => []));

export const hasErrors: <A>(v: ValErrMsgAR<A>) => boolean = E.fold(obj => Object.keys(obj ?? {}).length > 0, RA.isNonEmpty);

export function getValue<PC extends DataCodec, InnerKV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<Option<InnerKV>>>): Option<NonNullable<InnerKV>>;
export function getValue<PC extends DataCodec, KV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>> | UnsafeDataLens<PC, KV>): Option<NonNullable<KV>>;
export function getValue<PC extends DataCodec, KV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>): Option<NonNullable<KV>> {
  return fromNullableOrOption(formDataLens<PC>().compose(lens).get(state));
}

export const getValidationErrors = <PC extends DataCodec, KV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, KV>): O.Option<ValidationErrors<KV> | respCodecs.ServerValidationErrorsA> =>
  fromNullableOrOption<ValidationErrors<KV> | respCodecs.ServerValidationErrorsA>(
    formValidationErrorsLens<PC>()
      .compose(coerceLensAsValidationLens(lens))
      .get(state)
  );

const mapValidationErrorMessage = (config: BLConfigWithLog, errorTextContext?: ErrorTextContext) => (errs: respCodecs.ServerValidationErrorsA) =>
  errs.map(e => validationErrorMessage(config)(e, errorTextContext));

export const getValidationErrorsToValErrMsg = (config: BLConfigWithLog) => <PC extends DataCodec, KV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>,
  errorTextContext?: ErrorTextContext
): ValErrMsgAR<KV> => pipe(
  getValidationErrors(state, lens),
  O.fold(
    () => E.right<ValidationErrors<KV>, ValErrMsgA>([]),
    // If the direct field we are looking at does not have errors, return E.left with the inner errors.
    (e: ValidationErrors<KV> | respCodecs.ServerValidationErrorsA) => {
      if (respCodecs.serverValidationErrorsAC.is(e)) {
        return E.right<ValidationErrors<KV>, ValErrMsgA>(mapValidationErrorMessage(config, errorTextContext)(e));
      } else {
        return E.left<ValidationErrors<KV>, ValErrMsgA>(e);
      }
    },
  ));

export function getValErr<PC extends DataCodec, InnerKV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<Option<InnerKV>>>,
  errorTextContext?: ErrorTextContext
): (config: BLConfigWithLog) => ValErr<InnerKV>;
export function getValErr<PC extends DataCodec, A>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<A>> | UnsafeDataLens<PC, A>,
  errorTextContext?: ErrorTextContext
): (config: BLConfigWithLog) => ValErr<A>;

export function getValErr<PC extends DataCodec, A>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<A>>,
  errorTextContext?: ErrorTextContext
): (config: BLConfigWithLog) => ValErr<A> {
  return (config: BLConfigWithLog) => ({
    val: getValue(state, lens),
    err: getValidationErrorsToValErrMsg(config)(state, lens, errorTextContext),
  });
}

export const getValErrViaCodec = (config: BLConfigWithLog) => <PC extends DataCodec, KV>(
  state: FormState<PC>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>,
  codec: Codec<KV>,
  errorTextContext?: ErrorTextContext
): ValErrViaCodec<KV> => {
  const ve = getValErr(state, lens, errorTextContext)(config);
  return { ...ve, val: O.chain(codec.encode)(ve.val) };
};

export const getRawErrs = <PC extends DataCodec>(
  state: FormState<PC>
) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (lens: Optional<UnsafeFormData<PC>, UnsafeFormProp<any>>): Option<respCodecs.ServerValidationErrorsA> =>
    fromNullableOrOption(
      formValidationErrorsLens<PC>().asOptional()
        .compose(coerceOptionalAsValidationLens(lens)
        ).getOption(state));

export const getErrs = (config: BLConfigWithLog) => <PC extends DataCodec>(
  state: FormState<PC>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lens: ReadonlyArray<Optional<UnsafeFormData<PC>, UnsafeFormProp<any>>>,
  errorTextContext?: ErrorTextContext
): ReadonlyArray<ValErrMsgFn> =>
  pipe(
    lens.map(getRawErrs(state)),
    (e: Array<Option<respCodecs.ServerValidationErrorsA>>) =>
      RA.compact(e).map(
        (vs: respCodecs.ServerValidationErrorsA) =>
          respCodecs.serverValidationErrorsAC.is(vs) ? vs.map(err => validationErrorMessage(config)(err, errorTextContext)) : []),
    RA.flatten
  );

export const setErrorViaLens = <PC extends DataCodec, KV>(
  setState: Dispatch<SetStateAction<FormState<PC>>>,
  lens: UnsafeDataLens<PC, KV>
) => (e: ServerValidationError): void =>
    setState(state => formValidationErrorsLens<PC>().compose(coerceLensAsValidationLens(lens)).set([e])(state));

export type ServerValidationErrorMsg = {
  extra: Option<string>;
  error: ServerValidationError["error"];
};
